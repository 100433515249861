/**
 * WordPress dependencies
 */
import { SVG, Path, G } from "@wordpress/primitives";
import React from "react";

const fullScreen = (
    <SVG viewBox="0 0 19.491 19.878" width={24} height={24}>
        <G id="Group_610" transform="translate(1.5 1.5)">
            <Path
                id="Layer1_0_1_STROKES"
                d="M20.737,8.492V4.111H16.743M4.246,8.492V4.111H8.111M4.246,16.479v4.51H8.111m12.626-4.51v4.51H16.743"
                transform="translate(-4.246 -4.111)"
                fill="none"
                stroke="#081219"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
            />
        </G>
    </SVG>
);

export default fullScreen;
