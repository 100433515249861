import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './Main';

document.addEventListener('DOMContentLoaded', function (event) {
    const root = ReactDOM.createRoot(
        document.getElementById('maxi_demo_search')
    );
    root.render(
        <React.StrictMode>
            <Main type='patterns' />
        </React.StrictMode>
    );

    const addReload = () => {
        const allPatternButtons = document.querySelectorAll(
            '.maxi-cloud-container__patterns__top-menu .ais-Menu-link'
        );
        for (let i = 0; i < allPatternButtons.length; i++) {
            if (allPatternButtons[i].classList.contains('click-added')) return;
            const href =
                document.location.protocol +
                '//' +
                document.location.host +
                document.location.pathname;
            let parameter = '';
            switch (i) {
                case 0:
                    parameter = '';
                    break;
                case 1:
                    parameter = '?type=pages';
                    break;
                case 2:
                    parameter = '?type=playground';
                    break;
                default:
                    parameter = '';
                    break;
            }
            allPatternButtons[i].classList.add('click-added');
            allPatternButtons[i].addEventListener('click', function () {
                window.location.href = href + parameter;
                // root.render(
                //     <React.StrictMode>
                //         <Main type='patterns' />
                //     </React.StrictMode>
                // );
            });
        }
    };
    setTimeout(() => {
        const svgButton = document.getElementById('maxi-show_button__icons');
        svgButton.addEventListener(
            'click',
            function () {
                root.render(
                    <React.StrictMode>
                        <Main type='svg' />
                    </React.StrictMode>
                );
                const selectedItem = document.querySelector(
                    '.maxi-cloud-container__patterns__top-menu .ais-Menu-item--selected'
                );
                if (selectedItem)
                    selectedItem.classList.remove('ais-Menu-item--selected');

                svgButton.classList.add('ais-Menu-item--selected');
                addReload();
            },
            false
        );
        const scButton = document.getElementById('maxi-show_button__sc');
        scButton.addEventListener(
            'click',
            function () {
                root.render(
                    <React.StrictMode>
                        <Main type='sc' />
                    </React.StrictMode>
                );

                const selectedItem = document.querySelector(
                    '.maxi-cloud-container__patterns__top-menu .ais-Menu-item--selected'
                );
                if (selectedItem)
                    selectedItem.classList.remove('ais-Menu-item--selected');

                addReload();

                scButton.classList.add('ais-Menu-item--selected');
            },
            false
        );

        const patternsButton = document.getElementById('maxi-show_button__patterns');
        const pagesButton = document.getElementById('maxi-show_button__pages');
        const playgroundButton = document.getElementById('maxi-show_button__playground');
        setTimeout(function(){
             const menuPatterns1Button = document.querySelector(
                '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:first-child'
            ); 
            const menuPages1Button = document.querySelector(
                '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:nth-child(2)'
            );
            const menuPlayground1Button = document.querySelector(
                '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:nth-child(3)'
            );
            if(menuPatterns1Button.classList[1]){
                patternsButton.classList.add('ais-Menu-item--selected');
                menuPatterns1Button.classList.remove('ais-Menu-item--selected');
            }
            if(menuPages1Button.classList[1]){
                pagesButton.classList.add('ais-Menu-item--selected');
                menuPages1Button.classList.remove('ais-Menu-item--selected');
            }
            if(menuPlayground1Button.classList[1]){
                playgroundButton.classList.add('ais-Menu-item--selected');
                menuPlayground1Button.classList.remove('ais-Menu-item--selected');
            }
              
        },1000)

        patternsButton.addEventListener(
            'click',
            function () {

                const menuPatternsButton = document.querySelector(
                    '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:first-child a'
                );
                menuPatternsButton?.click();
                setTimeout(function(){
                    const menuPatternsButtonLi = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:first-child'
                    );
                    if(menuPatternsButtonLi.classList[1]){
                        menuPatternsButtonLi.classList.remove('ais-Menu-item--selected');
                    }
                    const selectedItem = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-item--selected'
                    );
    
                    if (selectedItem)
                        selectedItem.classList.remove('ais-Menu-item--selected');
    
                    addReload();
                    patternsButton.classList.add('ais-Menu-item--selected');
                },1000)

                
            },
            false
        );

        pagesButton.addEventListener(
            'click',
            function () {

                const menuPagesButton = document.querySelector(
                    '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:nth-child(2) a'
                );
                menuPagesButton?.click();
                setTimeout(function(){
                    const menuPagesButtonLi = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:nth-child(2).ais-Menu-item--selected'
                    );
                    if(menuPagesButtonLi){
                        menuPagesButtonLi.classList.remove('ais-Menu-item--selected');
                    }
                    const selectedItem = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-item--selected'
                    );
    
                    if (selectedItem)
                        selectedItem.classList.remove('ais-Menu-item--selected');
    
                    addReload();
                    pagesButton.classList.add('ais-Menu-item--selected');
                },1000)



                
            },
            false
        );

        playgroundButton.addEventListener(
            'click',
            function () {

                const menuPlaygroundButton = document.querySelector(
                    '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:nth-child(3) a'
                );
                menuPlaygroundButton?.click();
                setTimeout(function(){
                    const menuPlaygroundButtonLi = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:nth-child(3)'
                    );
                    if(menuPlaygroundButtonLi.classList[1]){
                        menuPlaygroundButtonLi.classList.remove('ais-Menu-item--selected');
                    }
                    const selectedItem = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-item--selected'
                    );
    
                    if (selectedItem)
                        selectedItem.classList.remove('ais-Menu-item--selected');
    
                    addReload();
                    playgroundButton.classList.add('ais-Menu-item--selected');
                },1000)

                
            },
            false
        );
    }, '500');
});
